import { gql } from '@apollo/client';

export const createOneDataItem = gql`
  mutation (
    $itemFile: Upload!
    $thumbnailFile: Upload!
    $triggerFile: Upload
    $scenery: String!
    $description: String!
    $name: String!
    $applicationId: Int!
    $dataPackageId: Int!
    $type: String!
    $triggerLongtitude: String
    $triggerLatitude: String
    $triggerAltitude: String
    $isAlpha: Boolean
    $offsetX: Float
    $offsetY: Float
    $offsetZ: Float
    $scale: Float
    $triggerName: String
    $actionUrl: String
  ) {
    createOneDataItem(
      data: {
        scenary: $scenery
        description: $description
        name: $name
        type: $type
        applicationId: $applicationId
        dataPackageId: $dataPackageId
        triggerName: $triggerName
        triggerFile: $triggerFile
        itemFile: $itemFile
        thumbnailFile: $thumbnailFile
        triggerLongtitude: $triggerLongtitude
        triggerLatitude: $triggerLatitude
        triggerAltitude: $triggerAltitude
        isAlpha: $isAlpha
        offsetX: $offsetX
        offsetY: $offsetY
        offsetZ: $offsetZ
        scale: $scale
        actionUrl: $actionUrl
      }
    ) {
      id
    }
  }
`;

export const getDataItems = gql`
  query (
    $contributorId: [Int!]
    $dataPackage: [Int!]
    $triggerType: [Scenary!]
    $itemType: [ItemType!]
    $apps: [Int!]
  ) {
    dataItems(
      where: {
        AND: [
          { contributorId: { in: $contributorId } }
          { dataPackageId: { in: $dataPackage } }
          { triggerType: { in: $triggerType } }
          { itemType: { in: $itemType } }
          { contributor: { id: { in: $apps } } }
        ]
      }
      orderBy: { id: desc }
    ) {
      id
      name
      description
      thumbnailPath
      triggerType
      itemType
      downloadsCount
      filePath
      createdAt
      dataPackage {
        id
        name
      }
      contributor {
        id
        application {
          id
          name

          scenaries
          platforms
        }
        user {
          email
          id
          firstname
          lastname
        }
      }
    }
  }
`;

export interface User {
  email?: string;
}

export interface ContentCreator {
  id: number;
  user: User;
  application?: App;
}

export interface App {
  id: number;
  name: string;
  scenaries?: string[];
  platforms?: string[];
  apiURL?: string;
  isPublic?: boolean;
}

export interface DataPackage {
  id: number;
  name: string;
  description?: string;
  tags: string[];
  thumbnailPath?: string;
}

export interface DataItem {
  id: number;
  name: string;
  actionUrl?: string;
  triggerType: string;
  thumbnailPath: string;
  itemType: string;
  filePath: string;
  description: string;
  downloadsCount?: number;
  contributor?: ContentCreator;
  dataPackage?: DataPackage;
  createdAt?: string;
}

export interface MyDataItemsResponse {
  dataItems: DataItem[];
}

export const deleteDataItem = gql`
  mutation ($dataItemId: Int!) {
    deleteOneDataItem(dataItemId: $dataItemId) {
      id
    }
  }
`;

export const getAllItems = gql`
  query {
    dataItems(orderBy: { downloadsCount: desc }) {
      id
      name
      description
      thumbnailPath
      triggerType
      itemType
      filePath
      downloadsCount
      createdAt
      dataPackage {
        id
        name
      }
      contributor {
        id
        application {
          id
          name

          scenaries
          platforms
        }
        user {
          email
          id
          firstname
          lastname
        }
      }
    }
  }
`;

/*      scale
      offsetX
      offsetY
      offsetZ
*/
export const getDataItem = gql`
  query ($id: Int!) {
    dataItem(where: { id: $id }) {
      id
      name
      description
      thumbnailPath
      triggerType
      itemType
      filePath
      createdAt
      dataPackage {
        id
        name
      }
      contributor {
        id
        application {
          id
          name
          isPublic
          scenaries
          platforms
          apiURL
        }
        user {
          email
          id
          firstname
          lastname
        }
      }
    }
  }
`;

export const updateDataItem = gql`
  mutation (
    $dataItemId: Int!
    $name: String
    $description: String
    $thumbnailFile: Upload
    $scale: Float
    $offsetX: Float
    $offsetY: Float
    $offsetZ: Float
    $dataPackageId: Int
    $movableContent: Boolean
    $isAlpha: Boolean
  ) {
    updateOneDataItem(
      data: {
        dataItemId: $dataItemId
        name: $name
        description: $description
        thumbnailFile: $thumbnailFile
        scale: $scale
        offsetX: $offsetX
        offsetY: $offsetY
        offsetZ: $offsetZ
        dataPackageId: $dataPackageId
        movableContent: $movableContent
        isAlpha: $isAlpha
      }
    ) {
      id
    }
  }
`;
