import React, {
  FC,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TailSpin } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { mutation } from '@src/adapters';
import { AuthContext } from '@src/context';
import Checkbox from '@ui-kit//checkbox';
import Button from '@ui-kit/button';
import { InputContainer, InputMessage } from '@ui-kit/input';
import Popup from '@ui-kit/popup';
import { colors } from '@ui-kit/theme';

import { DialogWindow } from '../../../../components/dialog/dialog-window';
import {
  Actions,
  Disclaimer,
  DopText,
  Input,
  ItemPayInfo,
} from './info-for-payment-modal.styles';

interface ConfirmationDialogProps {
  isOpened: boolean;
  onClose: () => void;
  onCloseSuccess: () => void;
  count: number;
  summ: number;
}

interface ProfileForm {
  organisationName?: string;
  inn: string;
  email: string;
  address: string;
  phone: string;
  count: string;
  summ: string;
}

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const dataPackageSchema = Yup.object<ProfileForm>({
  organisationName: Yup.string().required('Это обязательное поле'),
  inn: Yup.string()
    .required('Это обязательное поле')
    .test(
      'len',
      'Длина данного поля должна составлять 10 или 12 символов',
      (val) =>
        val !== null &&
        (val?.toString()?.length === 10 || val?.toString()?.length === 12)
    ),
  count: Yup.string(),
  summ: Yup.string(),
  address: Yup.string().required('Это обязательное поле'),
  phone: Yup.string()
    .required('Это обязательное поле')
    .matches(phoneRegExp, 'Введите корректный номер телефона'),
  email: Yup.string(),
});

export const InfoForPaymentModal: FC<ConfirmationDialogProps> = memo(
  (props) => {
    const { isOpened, onClose, onCloseSuccess, count, summ } = props;
    const { user, refetch } = useContext(AuthContext);
    const { t } = useTranslation();
    const [saveInfo, setSaveInfo] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formInfo, setFormInfo] = useState<ProfileForm | null>(null);

    const handleSubmit = (data: ProfileForm) => {
      setLoading(true);
      fetch('https://zrenie.dev1.geryon.space/payment/payments/createBill', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({ ...data, user_id: saveInfo ? user?.id : 0 }),
      })
        .then((res) => res.blob())
        .then((blob) => {
          setLoading(false);
          var file = window.URL.createObjectURL(blob);
          window.location.assign(file);
          onCloseSuccess();
        });
    };

    const getFormInfo = () => {
      fetch(
        `https://zrenie.dev1.geryon.space/payment/payments/paymentInfo/${user?.id}`,
        {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setFormInfo(data);
        });
    };

    useEffect(() => {
      if (formInfo !== null) {
        reset({ ...formInfo, count: String(count), summ: String(summ) });
      }
    }, [formInfo, count, summ]);

    useEffect(() => {
      getFormInfo();
    }, []);

    const {
      register,
      reset,
      handleSubmit: onSubmit,
      formState: { errors },
    } = useForm<ProfileForm>({
      defaultValues: useMemo(() => {
        return formInfo !== null
          ? { ...formInfo, count: String(count), summ: String(summ) }
          : {
              count: String(count),
              summ: String(summ),
              email: user?.email,
              organisationName:
                user?.firstname && user?.lastname
                  ? `${user?.firstname} ${user?.lastname}`
                  : user?.firstname || user?.lastname || '',
            };
      }, [formInfo]),

      resolver: yupResolver(dataPackageSchema),
    });

    return (
      <Popup variant="full" isOpened={isOpened} onClose={onClose}>
        <DialogWindow
          size="m"
          title={t('payment.entityData')}
          onClose={onClose}
        >
          <InputContainer error={!!errors?.organisationName}>
            <Input
              {...register('organisationName')}
              placeholder={t('payment.dataEntity.name')}
            />
            {errors?.inn && (
              <InputMessage>{errors?.organisationName?.message}</InputMessage>
            )}
          </InputContainer>

          <InputContainer error={!!errors?.inn}>
            <Input
              {...register('inn')}
              placeholder={t('payment.dataEntity.inn')}
            />
            {errors?.inn && <InputMessage>{errors?.inn?.message}</InputMessage>}
          </InputContainer>

          <InputContainer error={!!errors?.address}>
            <Input
              {...register('address')}
              placeholder={t('payment.dataEntity.address')}
            />
            {errors?.inn && <InputMessage>{errors?.inn?.message}</InputMessage>}
          </InputContainer>

          <InputContainer error={!!errors?.phone}>
            <Input
              {...register('phone')}
              placeholder={t('payment.dataEntity.phone')}
            />
            {errors?.phone && (
              <InputMessage>{errors?.phone?.message}</InputMessage>
            )}
          </InputContainer>

          <InputContainer error={!!errors?.email}>
            <Input
              {...register('email')}
              placeholder={t('payment.dataEntity.email')}
            />
            {errors?.email && (
              <InputMessage>{errors?.email?.message}</InputMessage>
            )}
          </InputContainer>

          <Disclaimer>{t('payment.dataEntity.declare')}</Disclaimer>

          <ItemPayInfo>
            <Checkbox
              checked={saveInfo}
              onChange={() => setSaveInfo(!saveInfo)}
            />
            <DopText>Запомнить данные юр. лица</DopText>
          </ItemPayInfo>

          <Actions>
            {
              <Button
                fullWidth
                onClick={onSubmit(handleSubmit)}
                variant="dark"
                text={
                  loading ? 'Загрузка...' : t('payment.dataEntity.btnTitle')
                }
              />
            }
          </Actions>
        </DialogWindow>
      </Popup>
    );
  }
);
