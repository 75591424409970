import {
  APP_TYPE,
  CREATE_AR_STEP,
  ITEM_TAB,
  ITEM_TYPE,
  SCENARIO,
} from '@src/core/types';

export default {
  common: {
    scenario: {
      [SCENARIO.BY_BODYPART]: 'Лицо',
      [SCENARIO.BY_IMAGE]: 'Изображение',
      [SCENARIO.BY_GEO]: 'Гео',
      [SCENARIO.BY_HERO]: 'Hiro метка',
      [SCENARIO.BY_OBJECT]: 'Объект',
      [SCENARIO.IN_SPACE]: 'Пространство',
    },
    type: {
      [ITEM_TYPE.IMAGE]: 'Изображение',
      [ITEM_TYPE.VIDEO]: 'Видео',
      [ITEM_TYPE.OBJECT]: '3D объект',
      [ITEM_TYPE.WIDGET]: 'Виджет',
    },
    next: 'Далее',
    back: 'Назад',
    save: 'Сохранить изменения',
    create: 'Создать',
    notFound: 'Элементы не найдены',
    logout: 'Выйти',
    connectApp: 'Подключить приложение',
    continue: 'Продолжайте в том же духе',
    menu: {
      createAr: 'Создать AR',
      media: 'Медиатека',
      library: 'Библиотека AR',
      faq: 'FAQ',
    },
    arSceneInfo: 'Информация по AR-сцене',
    alphaChannel: 'Альфа канал',
    selectFile: 'Выберите файл',
    download: 'Скачать',
  },
  media: {
    distribution: 'Дистрибуция',
    dataPackage: 'Пакет данных',
    arScenario: 'Сценарий AR',
    content: 'Отображаемый контент',
  },
  createAr: {
    selectScenario: {
      tip: 'Оживающие изображения из «Ежедневнго пророка» [cм. Гарри Поттера] - в качестве изображения для распознавания используйте первый кадр из видеоролика для отображения в AR-сцене. \n\n Воспроизводимое видео поверх изображения должно совпадать по пропорциям с изображением - тогда вы увидите настоящую магию!',
      title: {
        [SCENARIO.BY_IMAGE]: 'Изображение',
        [SCENARIO.BY_OBJECT]: 'Объект',
        [SCENARIO.BY_GEO]: 'Гео',
        [SCENARIO.BY_BODYPART]: 'Части тело',
        [SCENARIO.IN_SPACE]: 'Пространство',
      },
      description: {
        [SCENARIO.BY_IMAGE]: 'Изображения описание',
        [SCENARIO.BY_OBJECT]: 'Объекты описание',
        [SCENARIO.BY_GEO]: 'Гео описание',
        [SCENARIO.BY_BODYPART]: 'Части тела описание',
        [SCENARIO.IN_SPACE]: 'Пространство описание',
      },
    },
    selectApp: {
      action: 'Выберите приложение',
      actionDescription:
        'Здесь отображается развернутая информация об выбранном приложении',
      saveChoice: 'Запомнить выбор для следующей сессии',

      title: {
        [APP_TYPE.PRIVATE]: 'Доступ через закрытого оператора',
        [APP_TYPE.PUBLIC]: 'Доступ через публичного оператора',
        [APP_TYPE.EXPORT]: 'Конвертация и выгрузка файлов',
      },
      info: {
        [APP_TYPE.EXPORT]:
          'После создания AR-сцены вам будет предложено выбрать формат выгружаемых данных. \n\n Данный вариант дистрибуции подходит тем пользователям, кто планирует самостоятельно хранить и администрировать данные для своих мобильных инструментов на своем сервере.',
      },
      content: {
        [APP_TYPE.PRIVATE]: '',
        [APP_TYPE.PUBLIC]: '',
        [APP_TYPE.EXPORT]:
          'Вы сможете скачать единый архив со всеми форматами или выбрать один из возможных вариантов для конкретной программно-аппаратной базы: \n\n iOS app / Android app / Web app',
      },
      tip: {
        [APP_TYPE.PUBLIC]:
          'Выберите подходящего оператора для вашего контента из перечняпублично доступных. \n\n Вы можете подключить свои инструменты для агрегации пользовательского AR контента.',
        [APP_TYPE.PRIVATE]:
          'Хотите исключительное право на создание AR-контента для своего приложения? Пожалуйста! \n\n Вы можете подключить свои частные инструменты для тиражирования невероятных AR-сцен. Пробуйте специализировать разные инструменты в соответствии с различными задачами использования AR.',
        [APP_TYPE.EXPORT]:
          'Вперед, создавать AR! \n Ознакомьтесь с правилами использования выгружаемых данных -  [FAQ]',
      },
    },
    addItem: {
      title: {
        [ITEM_TAB.TRIGGER]: 'Определите триггер',
        [ITEM_TAB.UPLOAD]: 'Загрузите контент для отображения',
        [ITEM_TAB.SETTINGS]: 'Регулируйте отображение контента',
        [ITEM_TAB.DATA_PACKAGE]: 'Выберите пакет данных',
        [ITEM_TAB.NAME]: 'Идентифицируйте AR-сцену',
      },
      tip: {
        [ITEM_TAB.TRIGGER]:
          'Оживающие изображения из «Ежедневнго пророка» [cм. Гарри Поттера] - в качестве изображения для распознавания используйте первый кадр из видеоролика для отображения в AR-сцене. \n\n Воспроизводимое видео поверх изображения должно совпадать по пропорциям с изображением - тогда вы увидите настоящую магию!',
        [ITEM_TAB.SETTINGS]: '',
        [ITEM_TAB.NAME]: '',
        [ITEM_TAB.DATA_PACKAGE]: '',
        [ITEM_TAB.UPLOAD]: '',
      },
      content: {
        empty: 'Здесь отображается настраиваемая AR-сцена',
      },
      trigger: {
        widgetLabel: 'Введите ссылку',
      },
    },

    context: {
      info: {
        [CREATE_AR_STEP.APP]: {
          label: 'Выберите вариант дистрибуции AR контента',
          value:
            'Прежде чем создать AR, важно выбрать стратегию публикации контента. \n\n Уже подключили свое приложение? Если нет, то какой оператор вам подходит?',
        },
        [CREATE_AR_STEP.SCENARIO]: {
          label: 'Определите сценарий AR отображения',
          value:
            'Количество доступных сцениев определено возможностями выбранного оператора. \n\n Исследуйте новую точку зрения и вы преуспеете в создании AR!',
        },
        [CREATE_AR_STEP.SETTINGS]: {
          label: 'Настройте отображаемую AR сцену',
          value:
            'В зависимости от выбранного сценария выполните процедуру настройки контента. \n\n По итогу, опубликуйте или выгрузите контент, согласно выбранному варианту дистрибуции.',
        },
      },
    },

    createSuccess: 'Ar сцена успешно отредактирована',
    editSuccess: 'Ar сцена успешно отредактирована',
    createAr: 'Создать AR',
    successAction: 'Перейти в медиатеку',

    loadingConvertationTitle: 'Выполняется конвертация и загрузка файлов',
    loadingConvertationDescription:
      'Процесс может занять несколько минут. Пожалуйста, не покидайте страницу',

    triggerForm: {
      tip: 'Формат принимаемых данных: {{ format, string }}',
      tipByType: {
        by_image:
          'Рекомендуемое разрешение изображений не менее 1000px х 1000px воем сервере',
        by_object:
          'Предварительно, ознакомьтесь с гайдом по подготовке данного формата [FAQ]',
      },
    },
    contentForm: {
      tip: 'Формат принимаемых данных: {{ format, string }}',
      extraTip: 'Добавьте контент и настройте дополнительные опции',
      tipByType: {
        video:
          'Выберите опцию, если видео содержит хромокей — удаляемый цвет RGB: [0;255;0]',
        image:
          'Добавьте интерактивную гиперссылку для перехода на сторонние веб-ресурсы по нажатию на изображение',
      },
    },
    settings: {
      scale: 'Масштаб',
      offsetX: 'Ось X',
      offsetY: 'Ось Y',
      offsetZ: 'Ось Z',
    },
  },
  me: {
    publicApp: 'Ваши публичные приложения',
    privateApp: 'Ваши закрытые приложения',
    profile: {
      title: 'Данные пользователя',
      linkedSocial: 'Привязанные соц. сети',
      save: 'Обновить данные',
      disclaimer:
        'Заполните данные пользователя или привяжите к существующему аккаунту социальные сети, чтобы быстро зайти на портал',
    },
  },
  app: {
    apiUrl: 'Ключ-ссылка',
    name: 'Название',
    operator: 'Оператор',
    platforms: 'Платформы',
    sceneries: 'Сценарии',
    sceneCount: 'Кол-во AR',
    description: 'Описание',
    createdAt: 'Дата создания',
    file: 'Ссылка на файл',
    icon: 'Иконка',
    count: 'AR сцен',
    platform: {
      ios: 'iOS app',
      android: 'Android app',
      web: 'Web app',
    },
    qrIos: 'QR на iOS',
    qrAndroid: 'QR на Android',
    qrWeb: 'QR на веб',

    qrIosPlaceholder: 'Добавить веб-ссылку на скачивание',
    qrAndroidPlaceholder: 'Добавить веб-ссылку на скачивание',
    qrWebPlaceholder: 'Добавить веб-ссылку на скачивание',
  },
  sign: {
    welcome: 'Добро пожаловать в ARGOS',
    welcomeBack: 'С возвращением в ARGOS',
    login: 'Логин',
    email: 'Email',
    name: 'Имя',
    password: 'Пароль',
    passwordRepeat: 'Повторите пароль',
    signUp: 'Зарегистрироваться',
    backToSignIn: 'Назад к авторизации',
    signInVia: 'Войти через',
    signIn: 'Войти',
  },
  payment: {
    balance: 'Баланс',
    views: 'показов',
    entityData: 'Данные юридического лица',
    addBalance: 'Пополнить баланс на указанное количество показов',
    paySumm: 'Cумма к оплате:',
    rub: 'руб.',
    IAccept: 'Я принимаю',
    tariff: 'офферту тарификации',
    bill: 'Выставить счёт',
    units: 'eд.',
    ukassa: 'Оплата через ',
    ecosystem: 'экосистемы ZRENIE 2.0',
    oneViews: '1 показ = 1 рубль',
    viewsInBasket: 'Количество показов в корзине',
    descriptionsCard: {
      personal: 'для персонального использования',
      creative: 'для креативных экспериментов',
      big: 'для масштабных \n проектов',
    },
    dataEntity: {
      name: 'Наименование юридического лица',
      inn: 'ИНН',
      address: 'Юридический адрес',
      phone: 'Мобильный телефон',
      email: 'E-mail (необязательно)',
      declare: 'Укажите e-mail, чтобы дополнительно получить счет на почту.',
      btnTitle: 'Выставить счет',
    },
  },
  stat: {
    analitic: 'Аналитика показов',
    all: 'Всего',
    s: ' c',
    on: ' за',
    app: 'приложение',
    browser: 'веб-браузер',
    last30: 'За последние 30 дней:',
  },
};
