import React, { FC, memo, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TailSpin } from 'react-loader-spinner';

import { dateFormatter } from '@src/core/utils';
import Popup from '@ui-kit/popup';
import { colors } from '@ui-kit/theme';

import { DialogWindow } from '../../components/dialog/dialog-window';
import Chart from '../chart';
import {
  BoxValue,
  InfoBox,
  Line,
  StyledTable,
  Text,
} from './modal-statistics.styles';

interface ConfirmationDialogProps {
  id: any;
  onClose: () => void;
}

export interface ViewsByDateItem {
  date: string;
  totalViews: number;
  totalWebAndroidViews: number;
  totalWebIosViews: number;
  totalAppAndroidViews: number;
  totalAppIosViews: number;
}

export interface StatsItem {
  date: string;
  totalViews: number;
  totalFromCreation: number;
  totalWebAndroidViews: number;
  totalWebIosViews: number;
  totalAppAndroidViews: number;
  totalAppIosViews: number;
  viewsByDate: ViewsByDateItem[];
}

export const ModalStatistics: FC<ConfirmationDialogProps> = memo((props) => {
  const { id, onClose } = props;
  const { t } = useTranslation();
  const [info, setInfo] = useState<StatsItem | null>(null);
  const [selectedDate, setSelectedDate] = useState<ViewsByDateItem | null>(
    null
  );
  const [hoverdDate, setHoverDate] = useState<ViewsByDateItem | null>(null);

  const getStatisctics = () => {
    if (id !== null) {
      fetch(`https://argos.geryon.space/api/v1/cms/content/getStats/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
        .then((response) => response.json())
        .then((data: StatsItem) => {
          setInfo(data);
        });
    }
  };
  useEffect(() => {
    getStatisctics();
  }, [id]);

  const valueByKeys = (value: keyof ViewsByDateItem) =>
    hoverdDate && hoverdDate !== null
      ? hoverdDate[value]
      : selectedDate !== null
      ? selectedDate[value]
      : info !== null
      ? info[value]
      : '-';

  if (info === null)
    return (
      <TailSpin
        height="32"
        width="32"
        color={colors.background.yellow}
        ariaLabel="loading"
      />
    );

  return (
    <Popup variant="full" isOpened={id !== null} onClose={onClose}>
      <DialogWindow
        size="l"
        title={t('stat.analitic')}
        onClose={onClose}
        onClick={() => setSelectedDate(null)}
      >
        <InfoBox>
          <Text>
            {t('stat.all') + t('stat.s') + ': '}
            {dateFormatter(info?.date)}
          </Text>
          <BoxValue>{info?.totalFromCreation}</BoxValue>
        </InfoBox>
        <StyledTable>
          <colgroup>
            <col span={2} />
            <col />
          </colgroup>
          <tr>
            <th></th>
            <th>{t('stat.app')}</th>
            <th>{t('stat.browser')}</th>
          </tr>
          <tr>
            <td>Android</td>
            <td style={{ color: selectedDate ? '#FFC506' : 'black' }}>
              {valueByKeys('totalAppAndroidViews')}
            </td>
            <td style={{ color: selectedDate ? '#FFC506' : 'black' }}>
              {valueByKeys('totalWebAndroidViews')}
            </td>
          </tr>
          <tr>
            <td>IOS</td>
            <td style={{ color: selectedDate ? '#FFC506' : 'black' }}>
              {valueByKeys('totalAppIosViews')}
            </td>
            <td style={{ color: selectedDate ? '#FFC506' : 'black' }}>
              {valueByKeys('totalWebIosViews')}
            </td>
          </tr>
        </StyledTable>
        <Line>
          _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _{' '}
        </Line>
        <InfoBox>
          <Text>{t('stat.last30')}</Text>
          <BoxValue>{info?.totalViews}</BoxValue>
        </InfoBox>
        <Chart
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          info={info}
          hoverdDate={hoverdDate}
          setHoverdDate={setHoverDate}
        />
      </DialogWindow>
    </Popup>
  );
});
