import React, { ChangeEvent, FC, memo, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import '@reach/slider/styles.css';
import { CreateArContext } from '@src/context';
import RangeInput from '@src/pages/create-ar/components/screen-add-item/components/form-settings/components/range-input';

import { Root } from './form-settings.styles';

interface FormTriggerProps {}

interface SettingsForm {
  scale: number;
  offsetX: number;
  offsetY: number;
  offsetZ: number;
}

export const FormSettings: FC<FormTriggerProps> = memo((props) => {
  const { onDataItemChange, dataItem, setDisabledNext } =
    useContext(CreateArContext);
  const { t } = useTranslation();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onDataItemChange((dataItem) => {
      return { ...dataItem, [e.target.name]: Number(e.target.value) };
    });
  };

  useEffect(() => {
    setDisabledNext(false);
  }, []);

  return (
    <Root>
      <RangeInput
        label={t('createAr.settings.scale')}
        value={dataItem?.scale?.toString() || ''}
        onChange={(value) => {
          onDataItemChange((dataItem) => {
            return { ...dataItem, scale: Number(value) };
          });
        }}
      />
      <RangeInput
        label={t('createAr.settings.offsetX')}
        value={dataItem?.offsetX?.toString() || ''}
        min={-10}
        max={10}
        onChange={(value) => {
          onDataItemChange((dataItem) => {
            return { ...dataItem, offsetX: Number(value) };
          });
        }}
      />
      <RangeInput
        label={t('createAr.settings.offsetY')}
        value={dataItem?.offsetY?.toString() || ''}
        min={-10}
        max={10}
        onChange={(value) => {
          onDataItemChange((dataItem) => {
            return { ...dataItem, offsetY: Number(value) };
          });
        }}
      />
      <RangeInput
        label={t('createAr.settings.offsetZ')}
        value={dataItem?.offsetZ?.toString() || ''}
        min={-10}
        max={10}
        onChange={(value) => {
          onDataItemChange((dataItem) => {
            return { ...dataItem, offsetZ: Number(value) };
          });
        }}
      />
    </Root>
  );
});
