import React, {
  FC,
  MutableRefObject,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { TailSpin } from 'react-loader-spinner';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';

import { gql, useMutation, useQuery } from '@apollo/client';
import { mutation, queries } from '@src/adapters';
import { GetDataPackagesResponse } from '@src/adapters/data-package';
import { DataPackageDialog, DataPackageForm } from '@src/components/dialog';
import { AuthContext, CreateArContext } from '@src/context';
import { arrayFill } from '@src/core/utils';
import { InfoRadio, Item } from '@src/pages/create-ar/components/info-radio';
import { colors } from '@ui-kit/theme';

import { LoadingWrapper, Root } from './form-data-package.styles';

interface FormBaseInfoProps {}

const LOADER = arrayFill({ arr: [], count: 3 });
const Loader = () => (
  <>
    {LOADER.map((e, i) => (
      <>
        <LoadingWrapper key={`loader-${i}`} style={{ marginBottom: 20 }}>
          <Skeleton height={32} width={32} circle style={{ marginRight: 10 }} />
          <Skeleton height={20} width={160} />
        </LoadingWrapper>
        <LoadingWrapper key={`loader-${i}.${i}`} style={{ marginBottom: 20 }}>
          <Skeleton height={32} width={32} circle style={{ marginRight: 10 }} />
          <Skeleton height={20} width={200} />
        </LoadingWrapper>
      </>
    ))}
  </>
);

export const FormDataPackage: FC<FormBaseInfoProps> = memo((props) => {
  const [isCreateOpen, setIsCreateOpen] = useState<boolean>(false);
  const createArContext = useContext(CreateArContext);
  const { user } = useContext(AuthContext);
  const { data, loading, refetch } = useQuery<GetDataPackagesResponse>(
    queries.dataPackage.getDataPackages,
    {
      variables: {
        appId: createArContext.app?.id,
        userId: user?.id,
      },
    }
  );

  useEffect(() => {
    createArContext.setDisabledNext(!createArContext.dataItem?.dataPackageId);
  }, [createArContext.dataItem?.dataPackageId]);

  const [mutateCreateDataPackage, resultCreateDataPackage] = useMutation(
    mutation.dataPackage.createDataPackage,
    {
      onCompleted: (data) => {},
      onError: (data) => {
        toast.error('Произошла ошибка', {
          position: toast.POSITION.TOP_RIGHT,
        });
      },
    }
  );

  const handleRadioChange = (item: Item | undefined) => {
    if (!item?.id || item?.id === 0) {
      setIsCreateOpen(true);
      return;
    }

    createArContext.onDataItemChange((dataItem) => ({
      ...dataItem,
      dataPackageId: item?.id,
    }));
  };

  const items = useMemo(() => {
    if (!data?.dataPackages) {
      return [];
    }
    return [
      {
        label: 'Создать пакет',
        id: 0,
        isEmpty: true,
      },
      ...data.dataPackages.map(({ id, name, thumbnailPath }) => ({
        id,
        label: name,
        thumbnail: thumbnailPath,
      })),
    ];
  }, [data]);

  const handleCreateSubmit = (
    data: DataPackageForm,
    fileRef: MutableRefObject<HTMLInputElement | null>
  ) => {
    setIsCreateOpen(false);
    mutateCreateDataPackage({
      variables: {
        ...data,
        applicationId:
          createArContext.app?.id || createArContext.dataItem?.applicationId,
        thumbnailFile: fileRef.current?.files && fileRef.current?.files[0],
      },
      onCompleted: () => {
        toast.success('Дата пакет успешно создан', {
          position: toast.POSITION.TOP_RIGHT,
        });
        refetch();
      },
    });
  };

  return (
    <Root>
      {!loading && <InfoRadio items={items} onChange={handleRadioChange} />}
      {loading && <Loader />}

      {resultCreateDataPackage.loading && (
        <TailSpin
          height="32"
          width="32"
          color={colors.background.yellow}
          ariaLabel="loading"
        />
      )}

      <DataPackageDialog
        isOpened={isCreateOpen}
        onClose={() => setIsCreateOpen(false)}
        onSubmit={handleCreateSubmit}
      />
    </Root>
  );
});
