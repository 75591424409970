export const saveChoice = (data?: any) => {
  if (data) {
    localStorage.setItem('selectedCreateAr', JSON.stringify(data));
  } else {
    localStorage.removeItem('selectedCreateAr');
  }
};

export const getChoice = () => {
  const selectedCreateAr = localStorage.getItem('selectedCreateAr');
  if (selectedCreateAr) {
    try {
      return JSON.parse(selectedCreateAr) as { appId: number };
    } catch (e) {}
  }
  return;
};
