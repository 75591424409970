import styled, { css } from 'styled-components';

import { animations, colors, shape, typography } from '@ui-kit/theme';

export const Root = styled.div`
  width: 100%;
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  position: relative;
`;

export const Head = styled.thead``;
export const HeadCol = styled.th`
  text-align: left;
  padding: 8px;
  ${typography.regular12};
`;

export const Body = styled.tbody`
  width: 100%;
`;

export const HoverElementWrapper = styled.div`
  opacity: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  transition: all ease ${animations.duration};
`;

export const Row = styled.tr<{ index?: number; isActive?: boolean }>`
  background: ${colors.background.white};
  width: 100%;
  transition: all ease ${animations.duration};
  position: relative;
  border-radius: ${shape.rounding.L};
  overflow: hidden;
  transition: box-shadow ease ${animations.duration};
  z-index: ${({ index = 0 }) => index};
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    css`
      ${typography.bold16};
      box-shadow: 0 2px 30px rgba(0, 0, 0, 0.1);
    `};

  &:hover {
    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.1);
    position: relative;

    ${HoverElementWrapper} {
      opacity: 1;
    }
  }

  &:after {
    content: '';
    width: calc(100% - 16px);
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 8px;
    z-index: 1;
    background: ${colors.background.grayLight};
    transition: all ease ${animations.duration};
  }

  &:last-child {
    &:after {
      display: none;
    }
  }
`;

export const Col = styled.td`
  padding: 8px;
`;
