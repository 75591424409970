import React, { FC, memo, useEffect, useState } from 'react';

import { Dot, Item, ItemContent, Root } from './info-radio.styles';

export interface Item {
  id: number;
  label: string;
  isEmpty?: boolean;
  thumbnail?: string;
}

interface InfoRadioProps {
  items: Item[];
  onChange: (item?: Item) => void;
  initialValue?: number;
}

export const InfoRadio: FC<InfoRadioProps> = memo((props) => {
  const { items, onChange, initialValue } = props;
  const [selected, setSelected] = useState<number | undefined>();

  useEffect(() => {
    if (!selected && initialValue !== undefined) {
      setSelected(initialValue);
    }
  }, [initialValue]);

  const handleClick = (item: Item) => {
    setSelected(item.isEmpty ? item.id : item.id);
    onChange(item.isEmpty ? undefined : item);
  };

  return (
    <Root>
      {items.map((item) => (
        <Item key={`app-${item.id}`} onClick={() => handleClick(item)}>
          <Dot
            active={item.id === selected}
            variant={(item.isEmpty || undefined) && 'empty'}
            thumbnail={item.thumbnail}
          />
          <ItemContent>{item.label}</ItemContent>
        </Item>
      ))}
    </Root>
  );
});
