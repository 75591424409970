import YouTube from 'react-youtube';
import styled from 'styled-components';

import { Switcher as SwitcherUi } from '@ui-kit/switcher';
import { mixins, shape, typography } from '@ui-kit/theme';

export const Root = styled.div`
  ${mixins.flex({ horizontal: false })};
`;

export const Title = styled.h1`
  ${typography.medium24};
  margin: 0;
  margin-bottom: 20px;
  margin-top: 8px;
`;

export const ActionsRow = styled.div`
  ${mixins.flex({ horizontalAlignment: 'space-between' })};
  margin-bottom: 20px;
`;

export const InfoWrapper = styled.div`
  max-width: 540px;
`;

export const Switcher = styled(SwitcherUi)``;

export const LoadingWrapper = styled.div`
  ${mixins.flex({
    verticalAlignment: 'center',
  })}
`;

export const Buttons = styled.div`
  & > *:first-child {
    margin-right: 12px;
  }
`;

export const StyledYouTube = styled(YouTube)`
  width: 100%;
  height: 100%;
  border-radius: ${shape.rounding.L};
  overflow: hidden;

  iframe {
    width: 100%;
    height: 100%;
  }
`;
