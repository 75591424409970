import React, { FC, ReactNode, memo } from 'react';

import {
  ColumnContent,
  ColumnContentInner,
  ColumnMenu,
  ColumnMenuInner,
  Container,
  Tab,
  Tabs,
} from './switcher.styles';

export const styles = {
  ColumnContent: ColumnContentInner,
  ColumnMenu: ColumnMenu,
};

export interface SwitcherProps {
  tabs: {
    value: string;
    content: ReactNode;
    disabled?: boolean;
    thumbnail?: string;
  }[];
  info: ReactNode;
  content: ReactNode;
  currentTab: string;
  onTabChange?: (value: string) => void;
  className?: string;
}

export const Switcher: FC<SwitcherProps> = memo((props) => {
  const { tabs, info, content, currentTab, onTabChange, className } = props;

  return (
    <Container className={className}>
      <Tabs count={tabs.length}>
        {tabs.map((tab) => (
          <Tab
            active={currentTab === tab.value}
            onClick={() => onTabChange && onTabChange(tab.value)}
            key={`tab-${tab.value}`}
            clickable={!!onTabChange}
            disabled={tab.disabled}
          >
            {tab.content}
          </Tab>
        ))}
      </Tabs>
      <ColumnMenu
        count={tabs.length}
        currentIndex={tabs.findIndex((tab) => tab.value === currentTab)}
      >
        <ColumnMenuInner>{info}</ColumnMenuInner>
      </ColumnMenu>
      <ColumnContent>
        <ColumnContentInner>{content}</ColumnContentInner>
      </ColumnContent>
    </Container>
  );
});
