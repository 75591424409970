import React, { FC, memo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@ui-kit/button';

import {
  AuthFormTitle,
  AuthFormWrapper,
  AuthInput,
  InputMessage,
  InputWrapper,
  Wrapper,
} from '../../home.styles';
import { ActionsWrapper } from './registration-form.styles';

interface RegistrationFormProps {}

interface RegistrationFormData {
  login: string;
  email: string;
  password: string;
  passwordRepeat: string;
}

const requiredFieldMessage = 'Обязательное поле';

const registrationFormSchema = yup.object<RegistrationFormData>({
  login: yup.string().required(requiredFieldMessage),
  email: yup
    .string()
    .email('Некорректный email')
    .required(requiredFieldMessage),
  password: yup.string().required(requiredFieldMessage),
  passwordRepeat: yup
    .string()
    .oneOf([yup.ref('password')], 'Пароли не совпадают')
    .required(requiredFieldMessage),
});

export const RegistrationForm: FC<RegistrationFormProps> = memo((props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit: onSubmit,
    formState: { errors, isValid },
  } = useForm<RegistrationFormData>({
    resolver: yupResolver(registrationFormSchema),
    mode: 'all',
  });

  const handleSubmit = useCallback(
    (data: RegistrationFormData) => {
      const { passwordRepeat, ...userData } = data;
      fetch('https://argos.geryon.space/api/v1/auth/signup', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      }).then((response) => {
        toast.success(
          'Вы успешно зарегестрированы! Пожалуйста войдите в систему',
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        navigate('/');
      });
    },
    [navigate]
  );

  return (
    <AuthFormWrapper>
      <Wrapper>
        <AuthFormTitle>{t('sign.welcome')}</AuthFormTitle>
        <InputWrapper error={!!errors?.login}>
          <AuthInput
            {...register('login')}
            name="login"
            placeholder={t('sign.login')}
          />
          {errors?.login && (
            <InputMessage>{errors?.login?.message}</InputMessage>
          )}
        </InputWrapper>
        <InputWrapper error={!!errors?.email}>
          <AuthInput
            {...register('email')}
            name="email"
            type="email"
            placeholder={t('sign.email')}
          />
          {errors?.email && (
            <InputMessage>{errors?.email?.message}</InputMessage>
          )}
        </InputWrapper>
        <InputWrapper error={!!errors?.passwordRepeat}>
          <AuthInput
            {...register('password')}
            name="password"
            placeholder={t('sign.password')}
            type="password"
          />
        </InputWrapper>
        <InputWrapper error={!!errors?.passwordRepeat}>
          <AuthInput
            {...register('passwordRepeat')}
            name="passwordRepeat"
            placeholder={t('sign.passwordRepeat')}
            type="password"
          />
          {errors?.passwordRepeat && (
            <InputMessage>{errors?.passwordRepeat?.message}</InputMessage>
          )}
        </InputWrapper>
      </Wrapper>
      <ActionsWrapper>
        <Button
          onClick={onSubmit(handleSubmit)}
          text={t('sign.signUp')}
          variant="dark"
        />
        <Link to="/">{t('sign.backToSignIn')}</Link>
      </ActionsWrapper>
    </AuthFormWrapper>
  );
});
